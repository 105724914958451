.features-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 17px;

    &--center {
        justify-content: center;
    }

    @media (max-width: $medium) {
        width: 100%;
        gap: 10px;
        flex-direction: column;
        align-items: baseline;

        &--center {
            align-items: center;
        }
    }

    @media (max-width: $window-small) {
        flex-direction: column;
        align-items: flex-start;

        &--center {
            align-items: center;
        }
    }

    &__feature {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        flex: 0 0 auto;

        &__icon {
            margin-right: 12px;

            @media (max-width: $medium) {
                margin-right: 0;
                width: 50px;
                display: flex;
                justify-content: center;
            }
        }

        &__value {
            @media (max-width: $medium) {
                width: 75px;
            }
            @media (max-width: $window-small) {
                width: 100%;
            }
        }
    }
}
