@layer components {
    .popover {
        display: none;
        top: 0;
        bottom: 0;
        left: 0;
        max-height: 100%;
        border: none;
        right: 0;
        position: fixed;
        min-width: 100%;
        border-radius: 0;
        z-index: 40;
        background: white;
        overflow-y: auto;

        @media (min-width: $large) {
            position: absolute;
            top: 110%;
            right: 0;
            bottom: initial;
            left: initial;
            border: 1px solid $border-quiet;
            border-radius: 0;
            min-width: 100px;
            max-height: 460px;
        }

        &__header {
            display: flex;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            border-bottom: 1px solid $blue-link-water;
            padding: 15px;

            @media (min-width: $large) {
                display: none;
            }
        }

        &__title {
            font-weight: 600;
            margin-left: auto;
        }

        &__close {
            margin-left: auto;
            padding: 10px;
            border: none;
            background: none;
            cursor: pointer;
        }

        &__content {
            border-radius: 0;
            padding: 15px;
        }

        &--large {
            min-width: auto;

            @media (min-width: $large) {
                min-width: 500px;
            }
        }

        &--top {
            top: 0;
            transform: translateY(-100%);
        }

        &--left {
            top: 0;
            right: 102%;
        }

        &--right {
            top: 0;
            left: 100%;
            right: initial;
        }

        &--medium {
            min-width: 350px;
        }

        &__wrapper {
            position: relative;
        }

        &__overlay {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &__separator {
            @apply bg-content-quiet;
            height: 1px;
            border: 0;
            margin: 10px 0;
        }

        &__button {
            position: relative;
            border: 1px solid $border-quiet;
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            padding: 0 25px 0 10px;
            background: $white;
            border-radius: 0;
            min-height: 32px;
            background: url('../../images/svg/arrow.svg') no-repeat right white;
            background-size: 16px;
            background-position-x: 95%;
            line-height: 24px;

            &--borderless {
                border: none;
            }

            &:disabled {
                background-color: whitesmoke;
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        &__text {
            text-align: left;
            font-size: 14px;
        }

        &--mouseover {
            z-index: 100;
        }
    }
}

// Avoid tailwind purge to not loading these classes (because they are used with javascript)
.popover--show {
    display: block !important;
}

.popover__overlay--show {
    display: block !important;
}

.popover--large {
    min-width: auto;

    @media (min-width: $large) {
        min-width: 500px;
    }
}

.popover--medium {
    min-width: auto;

    @media (min-width: $large) {
        min-width: 350px;
    }
}

.popover__button--active {
    border: 1px solid $blue-brand;
    font-weight: 600;
}

.popover__button--borderless.popover__button--active {
    border: none;
    font-weight: 600;
}
