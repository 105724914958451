.sports {
    font-family: $font-primary;
    max-width: 374px;
    font-weight: 600;
    font-style: normal;
    color: $blue-primary;
    margin: auto;

    @media (min-width: $window-medium) {
        max-width: 1280px;
    }

    &__header {
        background-color: $white;
        margin-bottom: 16px;
        padding: 24px 16px;

        @media (min-width: $window-large) {
            text-align: center;
            padding: 24px;
        }
    }

    &__header-title {
        font-size: 32px;
        line-height: 32px;
        text-transform: uppercase;
        margin: auto;
        width: fit-content;

        h1 {
            margin-bottom: 8px;
        }
    }

    &__title-separator {
        margin-bottom: 24px;
        width: 64px;
        height: 4px;
        background-color: $blue-secondary;
    }

    &__listing {
        display: flex;
        flex-flow: row wrap;
        max-width: 1320px;
        margin: auto;
        gap: 24px;
        justify-content: center;
    }

    &__card {
        background-color: white;
        font-family: $font-primary;
        display: flex;
        flex-flow: column nowrap;
        max-width: 300px;
        width: 100%;
        box-shadow: 0px 6px 6px rgba(0, 83, 125, 0.1);
        border-radius: 8px;
    }

    &__card-head {
        width: 100%;
        height: 300px;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
        }
    }

    &__card-body {
        padding: 16px;
    }

    &__card-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}
