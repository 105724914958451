.input-switch {
    @apply relative inline-block h-[34px] w-[60px] min-w-[60px];

    & input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    input:checked + .slider {
        background-color: $blue-secondary;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $blue-secondary;
    }

    input:checked + .slider:before {
        transform: translateX(26px);
    }
}
