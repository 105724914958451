.list {
    & > ul,
    & > ol {
        margin: 20px 0;

        & > li {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 10px;
            margin: 10px 0;
        }
    }

    &--success {
        li {
            &:before {
                width: 24px;
                height: 24px;
                content: url('../img/check.svg');
            }
        }
    }

    &--error {
        li {
            &:before {
                width: 24px;
                height: 24px;
                content: url('../img/cross.svg');
            }
        }
    }

    &--option {
        li {
            &:before {
                width: 24px;
                height: 24px;
                content: url('../img/plus.svg');
            }
        }
    }
}
