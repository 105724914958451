.is-period {
    &[data-level='1'] > svg {
        fill: currentColor;
        color: #ffc474;
    }
    &[data-level='2'] > svg {
        fill: currentColor;
        color: #ffc639;
    }
    &[data-level='3'] > svg {
        fill: currentColor;
        color: #71d17f;
    }
}
