.button {
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    background-color: $blue-brand;
    color: #ffffff;
    font-weight: 600;

    &:hover {
        background-color: $blue-brand-hover;
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    &--full {
        width: 100%;
    }

    &--secondary {
        background-color: $blue-brand;

        &:hover {
            background-color: $blue-brand-hover;
        }
    }

    &--tertiary {
        background-color: $white;
        border: 2px solid $blue-tertiary;
        color: $blue-tertiary;

        &:hover {
            background-color: whitesmoke;
        }
    }

    &--action {
        background-color: $white;
        border: 2px solid $blue-action;
        color: $blue-action;

        &:hover {
            background-color: whitesmoke;
        }
    }

    &--large {
        padding: 14px 25px;
    }

    &--borderless {
        border: none;
        background: none;
        color: $blue-secondary;
        font-weight: 600;

        &:hover {
            background-color: $gray;
        }
    }
}
