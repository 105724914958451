.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    &__message {
        margin: 10px 0;
        text-align: center;
        font-size: 14px;
        white-space: normal;

        &--success {
            color: $blue-primary;
            font-weight: 600;
        }

        &--error {
            @apply text-status-negative;
        }
    }

    &__group {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;

        @media (max-width: $medium) {
            flex-direction: column;
        }

        &--inline {
            flex-direction: row;
            align-items: center;
        }
    }

    &__field {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 5px;

        @media (max-width: $medium) {
            width: 100%;
        }

        & > textarea {
            min-height: 130px;
            padding: 10px;
        }

        & > input {
            min-height: 30px;
            padding: 12px;

            &:focus {
                border-color: $blue-brand;
                border-width: 1px;
            }
        }

        &--sm {
            flex-grow: 1;
            flex-basis: 25%;
        }

        &--inline {
            flex-direction: row;
            align-items: center;
        }

        &--full {
            flex: auto;
        }

        &--space {
            width: 100%;
            justify-content: space-between;
        }

        &--checkbox {
            & > label {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }

    &__label {
        cursor: pointer;
        text-align: left;
        font-weight: 600;

        &--normal {
            font-weight: normal;
        }

        &--white {
            color: white;
        }

        &--required {
            &:after {
                content: '*';
                margin-left: 4px;
                @apply text-status-negative;
            }
        }
    }

    &__radio {
        cursor: pointer;
        padding: 16px;
        @apply border border-solid border-border-quiet;
        border-radius: 4px;

        &--active {
            border: 2px solid $blue-secondary;
        }

        .form__label {
            font-weight: 600;
            max-width: 75%;
        }
    }

    &__sublabel {
        display: block;
        opacity: 0.8;
        font-weight: normal;
        font-size: 14px;
    }

    &__actions {
        & > button[type='submit'] {
            margin-left: auto;
        }
    }

    &__error {
        position: absolute;
        bottom: -20px;
        font-size: 12px;
        @apply text-status-negative;
    }

    &__subtitle_above_number {
        flex-direction: row-reverse;

        &--margin-right {
            margin-right: 3rem;
        }

        &_one_element {
            font-size: 13px;

            &--margin-right {
                margin-right: 3.3rem;

                &-large-text {
                    margin-right: 1rem;
                }
            }
        }
    }
}

@layer base {
    /* TEXT INPUTS AND SELECTS */
    /* VARIABLES */
    $form-padding-horizontal: 12px;
    $form-padding-vertical: 12px;
    $form-border-width: 1px;
    $form-border-width-focus: 1px;
    $form-min-height: 48px;

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='url'],
    input[type='tel'],
    textarea,
    select,
    input[type='date'] {
        appearance: none;
        display: inline-block;
        margin: 0;
        padding: $form-padding-vertical $form-padding-horizontal;
        max-width: 100%;
        min-height: $form-min-height;
        outline: 0;
        background-color: white;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        border: $form-border-width solid $border-quiet;
        transition: border-color 0.5s;
        &:focus {
            border-color: $blue-primary;
            border-width: $form-border-width-focus;
            outline: none;
            box-shadow: none !important;
        }
        &:disabled {
            background-color: whitesmoke;
            color: inherit;
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='url'],
    input[type='tel'],
    textarea {
        &::-webkit-input-placeholder {
            color: $border-quiet;
        }
        &:-moz-placeholder {
            color: $border-quiet;
        }
        &::-moz-placeholder {
            color: $border-quiet;
        }
        &:-ms-input-placeholder {
            color: $border-quiet;
        }
        &.warning,
        &.warning:focus {
            border-color: $status-warning;
        }
    }
    textarea {
        &:not([cols]) {
            width: 100%;
        }
    }
    select {
        background: url('../../../apps/share/assets/svg/chevron-down.svg')
            no-repeat right center white;
        fill: $blue-primary;
        background-position: right $form-padding-horizontal center;
        background-size: 8px 6px;
        padding-right: calc(
            2 * $form-padding-horizontal + 8px
        ); /* largeur effective du picto + padding à droite */
        &:focus {
            box-shadow: none !important;
            background-image: url('../../../apps/share/assets/svg/chevron-down.svg');
            fill: $blue-primary;
        }
        &.warning,
        &.warning:focus {
            border-color: $status-warning !important;
            background-image: url('../../../apps/share/assets/svg/chevron-down.svg');
            fill: $status-warning;
        }
    }
    option {
        color: inherit;
    }
    /* CHECKBOXES */
    input[type='checkbox'] {
        appearance: none;
        outline: none;
        cursor: pointer;
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &:after {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            line-height: 1;
            box-shadow:
                0 0 0 2px white,
                0 0 0 calc(2px + $form-border-width) $border-quiet;
            background-color: white;
            transition:
                background-color 0.25s,
                box-shadow 0.25s;
        }
        &:checked:after {
            background-color: $blue-primary;
        }
        &:focus:after {
            box-shadow:
                0 0 0 2px white,
                0 0 0 calc(2px + $form-border-width) $blue-primary;
        }
        &.warning:after {
            box-shadow:
                0 0 0 2px white,
                0 0 0 calc(2px + $form-border-width) $status-warning;
        }
        &:disabled {
            box-shadow:
                0 0 0 2px white,
                0 0 0 calc(2px + $form-border-width) whitesmoke;
            cursor: not-allowed;
            opacity: 0.5;
        }
        /* @nest label > &:first-child:after {
            margin-right: 5px;
        }*/
    }

    input[type='radio'] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: var(--form-background);
        /* Not removed via appearance */
        margin: 0;

        @apply border-border-quiet text-content-quiet;
        width: 20px;
        height: 20px;
        border: 1.5px solid;
        border-radius: 50%;
        cursor: pointer;

        display: grid;
        place-content: center;
    }

    input[type='radio']::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em;
        @apply text-content-brand shadow-blue-brand;
        /* Windows High Contrast Mode */
        background-color: CanvasText;
    }

    input[type='radio']:checked {
        @apply border-blue-brand bg-transparent text-content-brand;
    }

    input[type='radio']:checked:focus,
    input[type='radio']:checked:hover {
        @apply border-blue-brand bg-transparent text-content-brand;
    }

    input[type='radio']:checked::before {
        transform: scale(1);
    }

    input[type='radio']:focus {
        outline: none;
        box-shadow: none;
    }
}
