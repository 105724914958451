.main-heading {
    display: block;
    position: relative;
    font-weight: $font-weight-primary;
    font-size: 24px;
    line-height: 30px;
    color: $text-neutral;
    margin-bottom: 15px;

    &:after {
        content: '';
        height: 4px;
        background: $blue-secondary;
        position: absolute;
        width: 100px;
        left: 0;
        bottom: -7px;
    }
}

.title {
    text-transform: uppercase;
    font-weight: 600;
}
