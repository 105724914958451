.travel-tour-nav {
    @apply mb-3xl inline-flex w-full flex-nowrap justify-between overflow-x-scroll
        whitespace-nowrap border-b border-solid border-border-quiet desktop:w-fit;

    & > .item {
        display: inline-block;
        & > .link {
            @apply relative mr-3xl block pb-m text-sm font-semibold transition-colors
                duration-[0.25s] hover:text-content-brand;
        }
        &.is-active > .link {
            color: $blue-brand;
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: $blue-brand;
                position: absolute;
                left: 0;
                bottom: -2px;
            }
        }
    }
}

.tour-descr-content {
    & strong {
        font-weight: 600;
    }

    & h1 {
        font-size: 2em;
        color: var(--color-prussian-blue);
        text-transform: uppercase;
    }

    & h2 {
        font-size: 1.8em;
        color: var(--color-lochmara);
    }

    & h3 {
        font-size: 1.6em;
        color: var(--color-prussian-blue);
    }

    & h4 {
        font-size: 1.4em;
        color: var(--color-lochmara);
    }

    & h5 {
        font-size: 1.2em;
        color: var(--color-prussian-blue);
    }

    & h6 {
        font-size: 1.1em;
        color: var(--color-lochmara);
    }

    & ul {
        list-style: disc outside none;
        padding-left: 20px;
        overflow: hidden; /* hack for avoiding the floating bug cf. https://stackoverflow.com/questions/710158/why-do-my-list-item-bullets-overlap-floating-elements */
    }

    & ol {
        list-style: decimal outside none;
        padding-left: 20px;
        overflow: hidden; /* hack for avoiding the floating bug cf. https://stackoverflow.com/questions/710158/why-do-my-list-item-bullets-overlap-floating-elements */
    }

    & blockquote {
        text-align: center;
        color: var(--color-primary);
        font-size: 1.2em;
        font-style: italic;
        font-weight: 600;
        line-height: 1.5;
    }

    & a {
        color: var(--color-warning);
    }
}

/* CONDITIONS : style particulier des puces de listes (écrase le style défini dans list-unordered-styling.pcss) */
.travel-tour-conditions-content {
    ul {
        list-style: none !important;
        padding-left: 0 !important;
    }

    ol {
        list-style: none !important;
        padding-left: 0 !important;
    }

    li {
        @apply relative mb-m pl-2xl;

        &:before {
            @apply absolute left-0 top-0 inline-block size-xl bg-no-repeat align-middle content-[''];
            background-size: 100%;
        }
    }
}

.is-included {
    li:before {
        background-image: url('../img/check-circle.svg');
    }
}

.is-excluded {
    li:before {
        background-image: url('../img/close-circle.svg');
    }
}

.is-extra {
    li:before {
        background-image: url('../img/add-circle.svg');
    }
}
