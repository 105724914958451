.lightbox-modal {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);

    &__content {
        position: relative;
        max-width: 1200px;
    }

    &__close {
        color: $white;
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 35px;
        font-weight: 600;

        &:hover,
        &:focus {
            color: $blue-action;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.splide__arrow svg {
    fill: white;
}
