// Media breakpoint
$large: 1300px;
$medium: 750px;
$small: 500px;

// Screen size
$window-large: 1280px;
$window-medium: 750px;
$window-small: 500px;
$window-xsmall: 370px;

$margin-bottom-primary: 24px;
$line-height-primary: 24px;
$font-weight-primary: 600;

// Colors
$blue-primary: #3643ba;
$blue-secondary: #3643ba;
$blue-tertiary: #3643ba;
$blue-action: #3643ba;
$blue-link-water: #e7f3f9;
$blue-rock: #9bbacd;
$blue-prussian: #00344f;
$white: #ffffff;
$black: #001018;
$gray: #eff1f3;

// VTMN PLAY
$border-quiet: #949494;
$text-neutral: #101010;
$blue-brand: #3643ba;
$blue-brand-hover: #2e3998;
$commercial-neutral: #e1e3f5;
$secondary-inverse-container-hover: rgba(255, 255, 255, 0.25);
$primary-inverse-container-hover: #ebecf8;
$status-warning: #db6900;

// Fonts
$font-primary: Inter, Arial, Helvetica, sans-serif;

$travel-tour-links-height: 70px;
