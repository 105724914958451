.is-header-open {
    background-color: white;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(
        var(--vh, 1vh) * 100
    ); /* see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
    position: fixed;
    transition: height 0.25s ease-out;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
