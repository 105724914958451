.loader {
    &__overlay {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background: $blue-primary;
        opacity: 0.5;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__icon {
        @apply animate-spin;
        width: 25px;
        height: 25px;
    }
}
