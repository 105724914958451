@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    src: url('../../apps/front/assets/css/fonts//Inter-Thin.ttf')
        format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    src: url('../../apps/front/assets/css/fonts//Inter-ExtraLight.ttf')
        format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url('../../apps/front/assets/css/fonts/Inter-Light.ttf')
        format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../../apps/front/assets/css/fonts//Inter-Regular.ttf')
        format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../../apps/front/assets/css/fonts//Inter-Medium.ttf')
        format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../../apps/front/assets/css/fonts//Inter-SemiBold.ttf')
        format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../../apps/front/assets/css/fonts//Inter-Bold.ttf')
        format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('../../apps/front/assets/css/fonts//Inter-ExtraBold.ttf')
        format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url('../../apps/front/assets/css/fonts//Inter-Black.ttf')
        format('truetype');
    font-display: fallback;
}
