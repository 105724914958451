.input-select {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 30px 0 10px;
    color: $blue-primary;
    background: $white;
    border-radius: 5px;
    min-height: 32px;
    background: url('../../images/svg/arrow.svg') no-repeat right white;
    background-size: 16px;
    background-position-x: 97%;
    line-height: 24px;
    font-size: 14px;

    &:focus,
    &:active {
        background: url('../../images/svg/arrow.svg') no-repeat right white;
        background-size: 16px;
        background-position-x: 97%;
    }
}
