#widget-payment-kit .op-card .wpwl-wrapper-brand {
    @media (max-width: $window-small) {
        height: 100%;
    }
}

#widget-payment-kit #op-primary-payment-block {
    border-radius: 0;
}

#widget-payment-kit #op-secondary-payment-block {
    border-radius: 0;
}

// FIXME form.pcss :root rule override min-height for all text inputs
#vtmn-tooltip-aci-creditcard input {
    min-height: unset;
}

div#payment-informations {
    & > div:before {
        content: none;
    }
}
