@import '../features-list';

.front-main {
    &.cover {
        background-repeat: no-repeat;
        background-size: cover;
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
