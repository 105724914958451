/* TOP 3 */
.is-top1 {
    @apply mx-auto;
    & > .photo {
        height: 400px;
    }

    @media (min-width: $large) {
        float: left;
        width: 630px;
        margin-top: 0;
        & > .photo {
            height: 410px;
        }
    }
}

.is-top2 {
    @apply mx-auto;

    & > .photo {
        height: 300px;
    }

    @media (min-width: $large) {
        float: right;
        width: 415px;
        & > .photo {
            height: 270px;
        }
    }
}

.is-top3 {
    @apply mx-auto;

    & > .photo {
        height: 200px;
    }

    @media (min-width: $large) {
        float: left;
        width: calc(630px - 330px);
        margin-left: 330px;
        & > .photo {
            height: 430px;
        }
    }
}
