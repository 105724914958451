/*
 * Order is defined by business logic
 */
.mosaic-destination:nth-child(1) {
    @media (min-width: $large) {
        grid-row: span 2;
        grid-column: span 1;
        order: 1;
    }
}

.mosaic-destination:nth-child(2) {
    @media (min-width: $large) {
        grid-row: span 1;
        grid-column: span 1;
        order: 5;
    }
}

.mosaic-destination:nth-child(3) {
    @media (min-width: $large) {
        grid-row: span 1;
        grid-column: span 1;
        order: 2;
    }
}

.mosaic-destination:nth-child(4) {
    @media (min-width: $large) {
        grid-row: span 1;
        grid-column: span 1;
        order: 4;
    }
}

.mosaic-destination:nth-child(5) {
    @media (min-width: $large) {
        grid-row: span 1;
        grid-column: span 2;
        order: 6;
    }
}

.mosaic-destination:nth-child(6) {
    @media (min-width: $large) {
        grid-row: span 2;
        grid-column: span 1;
        order: 3;
    }
}
