.banner {
    width: 100%;
    min-height: 300px;
    page-break-inside: avoid;

    &--primary {
        background-color: $blue-primary;
    }

    &--secondary {
        background-color: $blue-secondary;
    }
}

.front-banner-flash {
    padding: 16px 20px;
    background-color: $blue-brand;
    color: white;
    text-align: center;
    @media desktop {
        padding-left: 0;
        padding-right: 0;
    }
    & > .content {
        max-width: var(--window-large);
        margin: 0 auto;
        & a {
            /* annule la couleur définie dans link-styling.pcss */
            color: inherit;
            &:hover {
                color: var(--color-commercial-neutral);
            }
        }
    }
}

.banner-gradient-top {
    background: linear-gradient(
        180deg,
        rgba(16, 16, 16, 0.64) 14.36%,
        rgba(0, 51, 77, 0) 100%,
        rgba(16, 16, 16, 0) 100%
    );
    /*
    background: linear-gradient(
        180deg,
        rgba(0, 15, 23, 0.9) 0%,
        rgba(0, 51, 77, 0) 100%,
        rgba(0, 15, 23, 0) 100%
    );
    */
}

.banner-gradient-bottom {
    background: linear-gradient(
        0deg,
        rgba(16, 16, 16, 0.6) 37.01%,
        rgba(0, 51, 77, 0) 100%,
        #101010 100%
    );
    /*
    background: linear-gradient(
        0deg,
        rgba(0, 15, 23, 0.9) 0%,
        rgba(0, 51, 77, 0) 100%,
        rgba(0, 15, 23, 0) 100%
    );
    */
}
