$flat-inspiration-blocks-item-width: 416px;
$flat-inspiration-blocks-item-height: 272px;
$flat-inspiration-blocks-gutter-size: 16px;

.is-1x2 {
    grid-row-start: span 2;
    height: calc(
        2 * $flat-inspiration-blocks-item-height +
            $flat-inspiration-blocks-gutter-size
    );
}
.is-2x1 {
    grid-column-start: span 2;
    width: calc(
        2 * $flat-inspiration-blocks-item-width +
            $flat-inspiration-blocks-gutter-size
    );
}
.is-2x2 {
    grid-row-start: span 2;
    grid-column-start: span 2;
    width: calc(
        2 * $flat-inspiration-blocks-item-width +
            $flat-inspiration-blocks-gutter-size
    );
    height: calc(
        2 * $flat-inspiration-blocks-item-height +
            $flat-inspiration-blocks-gutter-size
    );
}
