.sitemap {
    font-family: $font-primary;
    font-style: normal;
    color: $blue-primary;
    margin: auto;
    background: $white;
    padding: 20px;

    ul {
        padding: 10px 0;
        margin: 0 0 16px 0;
        list-style: circle;

        li {
            padding: 0 10px;
            &.parent {
                ul {
                    margin-bottom: 8px;
                }
            }

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
