.filters {
    width: 100%;

    @media (max-width: $window-large) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        background: white;
        z-index: 40;
        max-width: none;
        margin: 0;
    }

    &--hidden {
        @media (max-width: $window-large) {
            display: none;
        }
    }

    &__header {
        display: none;

        @media (max-width: $window-large) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 14px;
            border-bottom: 1px solid lightgrey;
        }
    }

    &__label {
        font-size: 14px;
        color: $text-neutral;
        font-weight: 600;
    }

    &__title {
        display: flex;
        margin-left: auto;
        align-items: center;
        font-weight: 600;

        & > svg {
            margin-left: 8px;
        }
    }

    &__close {
        cursor: pointer;
        border: none;
        background: none;
        margin-left: auto;
    }

    // Alternative to change button style on sticky
    &__button-detector {
        height: 1px;
    }

    &__button {
        display: none;

        @media (max-width: $window-large) {
            position: sticky;
            justify-content: center;
            color: $text-neutral;
            border: none;
            box-shadow: 0 6px 6px rgba(0, 83, 125, 0.1);
            top: 0;
            right: 0;
            left: 0;
            display: flex;
            align-items: center;
            padding: 15px;
            margin: 16px auto 0 auto;
            text-align: center;
            z-index: 4;
            width: 100%;
            max-width: 95%;
            border-radius: 8px;
            background-color: #ffffff;
            font-weight: 600;
            transition: all 200ms ease-out;

            &--sticky {
                max-width: 100%;
                border-radius: 0;
            }

            & > svg {
                margin-left: 8px;
            }
        }
    }

    &__block {
        margin-bottom: 15px;
        border-radius: 8px;
        background-color: $white;
        box-shadow: 0 6px 6px rgba(0, 83, 125, 0.1);

        @media (max-width: $window-large) {
            flex-basis: 100%;
            box-shadow: none;
            overflow-y: scroll;
        }
    }

    &__active {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $window-large) {
            display: none;
        }
    }

    &__footer {
        display: none;

        @media (max-width: $window-large) {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: auto;
            padding: 16px;
            border-top: 1px solid lightgrey;
        }
    }

    .filter-chip {
        background-color: white;
        border-radius: 25px;
        border: 1px solid #8996a2;
        padding: 3px 8px;
        color: $blue-brand;
        font-size: smaller;
        font-weight: 600;

        margin: 5px;

        display: flex;
        align-items: center;

        svg {
            margin-right: 8px;
        }
    }

    &__container {
        width: 100%;
        padding: 8px 16px;

        @media (min-width: $window-large) {
            display: flex;
            align-items: flex-end;
            gap: 50px;
            justify-content: center;
            width: 100%;
            padding: 8px 16px 0 16px;
        }
    }

    &__inputs-container {
        display: flex;
        gap: 32px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;

        @media (max-width: $window-large) {
            margin: 10px 0;
            flex-direction: column;
            align-items: flex-start;
        }

        @media (min-width: $window-large) {
            gap: 50px;
        }

        &--full {
            flex: 1;

            @media (min-width: $window-large) {
                gap: 0px;
            }
        }
    }

    &__input-container {
        display: flex;
        flex-direction: column;

        &--inline {
            flex-direction: row;
        }
    }

    &__input-flexibility {
        width: 100%;
        display: flex;
        flex: 1;
        @media (min-width: $window-large) {
            align-items: flex-end;
        }

        & > select {
            min-width: 120px;
            border: 1px solid $gray !important;
            border-radius: 4px !important;
            padding: 12px !important;
            color: $text-neutral;
            min-height: auto !important;
        }
    }

    &__input-gir {
        gap: 10px;
    }

    &__input-pax,
    &__input-flexibility {
        .input-number__less {
            width: 50px;
            height: 50px;
        }

        .input-number__value {
            height: 50px;
        }

        .input-number__more {
            width: 50px;
            height: 50px;
        }

        .days {
            margin-left: 12px;
            margin-bottom: 12px;
        }
    }

    &__date {
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    &__date-input {
        border: 1px solid $gray !important;
        border-radius: 4px !important;
        padding: 12px !important;
        color: $text-neutral;
        min-height: auto !important;
        position: relative;
        @media (min-width: $window-large) {
            width: 310px;
        }
    }

    &__submit {
        max-width: 340px;
        width: 100%;
        background-color: $blue-tertiary;
        color: $white;
        padding: 12px 32px;
        border-radius: 48px;
        display: block;

        &--desktop {
            @media (max-width: $window-large) {
                display: none;
            }
        }

        @media (min-width: $window-large) {
            max-width: 147px;
            margin-bottom: 0;
        }

        @media (max-width: $window-large) {
            margin: 10px auto;
        }

        @media (max-width: $window-large) {
            max-width: 210px;
            margin: 10px 0;
        }

        &--with-results {
            display: none;
            max-width: 250px;
        }
    }

    &__choices {
        padding: 0 16px;

        @media (min-width: $window-large) {
            padding: 0 0 16px 0;
            display: flex;
            justify-content: center;
            gap: 80px;
        }

        &--hidden {
            @media (max-width: $window-large) {
                display: none;
            }
        }
    }

    &__vertical-separator {
        display: none;

        @media (min-width: $window-large) {
            display: flex;
            margin: 0;
            height: 48px;
            width: 1px;
            @apply bg-content-quiet;
            border: 0;
        }
    }

    &__separator {
        @media (min-width: $window-large) {
            height: 1px;
            width: 100%;
            margin: 12px 0;
            color: $gray;
        }
    }

    &__more-filters {
        display: flex;
        justify-content: center;
        margin-bottom: 18px;

        @media (max-width: $window-large) {
            display: none;
        }

        @media (min-width: $window-large) {
            display: none;
        }
    }

    &__secondary {
        display: flex;
        align-items: center;
        color: $blue-action;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;

        @media (min-width: $window-large) {
            max-width: max-content;
        }
    }

    &__icon {
        &--left {
            margin-right: 8px;
        }

        &--right {
            margin-left: 8px;
        }
    }
}
