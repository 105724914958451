@tailwind base;
@layer base {
    b,
    strong {
        font-weight: 600;
    }
}
@tailwind components;
@tailwind utilities;

// needed for vtmn-play integration
@import 'global.css';

@import 'variables';
@import 'fonts';

@import 'common/form';
@import 'common/select-multiple';
@import 'common/button';
@import 'common/input-switch';
@import 'common/typography';
@import 'common/lightbox-modal';
@import 'common/popover';
@import 'common/input-select';
@import 'common/loader';
@import 'common/banner';
@import 'common/list';
@import 'common/card';
@import 'common/quilljs';

@import 'components/header-mobile';
@import 'components/input-number';
@import 'components/partials/filters';
@import 'components/partials/pax-selector';

@import 'components/reviews.scss';

@import 'ski/booking/confirmation.scss';
@import 'ski/page-headers.scss';

@import 'views/booking/payment.scss';
@import 'views/tour';
@import 'views/travel-spot';
@import 'views/landingpage';

@import 'flat/sitemap.scss';
@import 'flat/sports.scss';
@import 'flat/inspiration.scss';

@import 'components/homepage/destinations.scss';

body {
    display: flex;
    flex-direction: column;
    font-family: $font-primary;
    font-style: normal;
}
