.select-multiple {
    width: 100%;
    cursor: pointer;

    @media (min-width: $window-large) {
        width: auto;
        max-width: 120px;
    }

    &__choice {
        padding-left: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $text-neutral;
        margin: 5px 0;
        background-color: white;
        justify-content: space-between;
        display: flex;

        @media (min-width: $window-large) {
            font-weight: 600;
        }

        &.parent {
            font-weight: 600;
        }
        &.children {
            margin-left: 12px;
            font-weight: normal;
        }
        &.hidden {
            display: none;
        }

        input {
            margin-right: 7px;
            margin-left: 10px;
        }
    }

    &__choices {
        display: none;
        list-style: none;
        margin-bottom: 0;
        max-height: 336px;
        background-color: rgba(255, 255, 255, 1);
        overflow-y: auto;

        &--sm-no-scroll {
            @media (max-width: $small) {
                overflow-y: auto;
                max-height: none;
            }
        }

        @media (min-width: $window-large) {
            border-top: solid 3px $blue-brand;
            -webkit-box-shadow: 0 5px 6px rgb(0 0 0 / 25%);
            box-shadow: 0 5px 6px rgb(0 0 0 / 25%);
            padding: 8px 8px 8px 8px;
        }
    }

    // Default is hidden
    &__display &__choices {
        display: block;
        @media (min-width: $window-large) {
            position: absolute;
            white-space: nowrap;
            z-index: 3;
        }
    }

    &__select {
        display: flex;
        align-items: center;
        padding: 16px;

        @media (min-width: $window-large) {
            margin: 0;
            padding: 0;
        }
    }

    &__select-input {
        background: none;
        border: none;
        width: 100%;
        margin-right: 5.64px;
        color: $text-neutral;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: left;

        @media (min-width: $window-large) {
            max-width: max-content;
            margin-right: 16px;
        }
    }

    &__separator {
        width: 100%;
        height: 1px;
        background: $gray;
        border: none;

        @media (min-width: $window-large) {
            display: none;
        }
    }

    input {
        outline: none;
        border: none;
    }
}
