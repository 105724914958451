.ski-header {
    position: relative;
    overflow: hidden;

    &__help__cta {
        transition: all 0.5s;
        border-radius: 200px;
        border: solid 2px #fff;
        transition-property: color, border-color, background-color;
        color: white;

        &:hover {
            color: $commercial-neutral;
            border-color: $commercial-neutral;
            background-color: $secondary-inverse-container-hover;

            svg path {
                fill: $commercial-neutral;
            }
        }

        &--link {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 16px 32px;
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            white-space: nowrap;
            cursor: pointer;
            line-height: 1;
            text-decoration: none;
            color: inherit;

            @media (max-width: $window-large) {
                padding: 12px;
            }

            span {
                @media (max-width: $window-medium) {
                    display: none;
                }
            }

            svg path {
                transition: all 0.5s !important;
            }
        }
    }
}

// Override .pcss styling
.front-banner > .inner {
    padding-top: 35px;
}
