.cards {
    display: grid;
    grid-template-columns: repeat(1, minmax(320px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
    margin: 0 auto;

    @media screen and (min-width: $medium) {
        grid-template-columns: repeat(2, minmax(320px, 1fr));
        grid-gap: 40px;
    }

    @media screen and (min-width: $large) {
        grid-template-columns: repeat(3, minmax(320px, 1fr));
    }

    @media screen and (min-width: 1568px) {
        grid-template-columns: repeat(4, minmax(320px, 1fr));
    }
}
