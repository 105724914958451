.listing-reviews {
    margin: 0 auto 32px;
    // TODO variable different from media query
    max-width: 1440px;
    padding: 0 64px;

    @media (max-width: $window-large) {
        width: 100%;
        padding: 24px 20px;
    }
}
